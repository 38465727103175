<template lang="pug">
    div.thanks-page
        div.img-container
            img.logo(src="/img/logo-header.png")
        div.thanks-block
            div.content-text
                p.text-averta-light-32 {{$t('thanks')}}
                p.text-averta-regular-20 {{$t('thanks.description')}}
            div.button-block
                button.button.btn-primary-square(@click="confirm()") {{$t('thanks.go')}}
</template>

<script>
import UserProfileService from "skillbase-component/src/components/user-profile/services/UserProfileService.js";

export default {
    name: "Thanks",
    methods: {
        confirm() {
            UserProfileService.setConsented().then(response => {
                this.$router.push('/home')
            })
        }
    }
}
</script>

<style scoped lang="scss">
.thanks-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: url("/img/welcomepage.png");
    background-size: cover;
    height: 100vh !important;
    overflow: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    margin-left: 0 !important;
    width: 100vw !important;

    .logo {
        position: absolute;
        top: 46px;
        left: 46px;
        width: 160px;
        filter: var(--svg-filter-white-color);
    }

    .thanks-block {
        width: 490px;
        background: white;
        border-radius: 5px;
        padding: 35px;
        height: fit-content;

        .content-text {
            height: fit-content;

            .text-averta-regular-20 {
                margin-top: 35px;
            }
        }

        .button-block {
            float: right;
            margin-top: 56px;
        }
    }
}
</style>